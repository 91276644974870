import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


export enum IdleUserTimes {
  IdleTime = 300000,
  CountdownTime = 30000
}

@Injectable({
  providedIn: 'root'
})
export class IdleUserService {
  private timeoutId: any;
  private countdownId: any;
  private countdownValue: number;

  userInactive: Subject<boolean> = new Subject();
  userReActived: Subject<boolean> = new Subject();
  userAboutToInactive: Subject<number> = new Subject();
  countDownStarted:boolean=false;
  constructor() {
    this.reset();
    this.initListener();
    console.log("Controle de inatividade em ",IdleUserTimes.IdleTime, "ms e com contagem regressiva de ",IdleUserTimes.CountdownTime,"ms")
  }

  initListener() {
    window.addEventListener('mousemove', () => this.reset());
    window.addEventListener('click', () => this.reset());
    window.addEventListener('keypress', () => this.reset());
    window.addEventListener('DOMMouseScroll', () => this.reset());
    window.addEventListener('mousewheel', () => this.reset());
    window.addEventListener('touchmove', () => this.reset());
    window.addEventListener('MSPointerMove', () => this.reset());
  }

  reset() {
    clearTimeout(this.timeoutId);
    clearTimeout(this.countdownId);
    this.startIdleTimer();
    
    if(this.countDownStarted==true){
        console.log("reset ativado");
        this.userReActived.next(true);
        
    }
    this.countDownStarted=false
    
  }

  startIdleTimer() {
    this.timeoutId = setTimeout(() => {
      console.log('Inactivity detected');
      this.startCountdown();
    }, IdleUserTimes.IdleTime);
  }

  startCountdown() {
    this.countdownValue = IdleUserTimes.CountdownTime / 1000;
    this.countdownId = setInterval(() => {
      this.countDownStarted=true;
      this.countdownValue--;
      console.log('Logoff em :', this.countdownValue, 'seconds');
      this.userAboutToInactive.next(this.countdownValue);
      
      if (this.countdownValue <= 0) {
        clearInterval(this.countdownId);
        console.log('User idle');
        this.userInactive.next(true);
      }
    }, 1000);
  }
}