import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";
import { BrowserModule } from "@angular/platform-browser";
import { ReactiveFormsModule } from "@angular/forms";

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';
import { OSMModule } from './pages/osm/osm.module';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { NgHttpLoaderModule } from 'ng-http-loader';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from "@angular/common/http";


import { LoginPageComponent } from "./login-page/login-page.component";

import { DefinePasswordComponent } from "./recover-password/define-password.component";
import { ForgotPasswordComponent } from "./recover-password/forgot-password.component";

import { AuthServiceService } from "./biz/auth-service.service";
import { JwtInterceptor } from "./biz/_helpers/jwt.interceptor";
import { ErrorInterceptor } from "./biz/_helpers/error.interceptor";
import { UserService } from "./biz/user.service";
import { AuthGuard } from "./biz/_helpers/auth.guard";

import { DataTablesModule } from 'angular-datatables';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';

import { MessagesComponent }    from './shared/messages/messages.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    PublicLayoutComponent,
    LoginPageComponent,
    ForgotPasswordComponent,
    DefinePasswordComponent,
    MessagesComponent
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true
    }),
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot({preventDuplicates: true}),
    FooterModule,
    FixedPluginModule,
    DataTablesModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthServiceService,
    UserService],
  bootstrap: [AppComponent]
})
export class AppModule { }
