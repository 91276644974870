import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { LoginPageComponent } from "./login-page/login-page.component";
import { AuthGuard } from "./biz/_helpers/auth.guard";
import { ForgotPasswordComponent } from "./recover-password/forgot-password.component";
import { DefinePasswordComponent } from "./recover-password/define-password.component";

export const AppRoutes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent
  },
  {
    path: 'lembrar_senha',
    component: ForgotPasswordComponent
  },
  {
    path: 'nova_senha',
    component: DefinePasswordComponent  
  },
  {
    path: 'public',
    component: PublicLayoutComponent,
    
    children: [
        {
      path: '',
      loadChildren: './layouts/public-layout/public-layout.module#PublicLayoutModule'
      }
    ]
  }
  , {
    path: 'home',
    component: AdminLayoutComponent,
    
    children: [
        {
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
  }]
  , canActivate: [AuthGuard]
}
  ,
  {
    path: '**',
    redirectTo: 'public'
  } 
]
