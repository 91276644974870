import { Component, HostBinding, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
    moduleId: module.id,
    selector: 'footer-cmp',
    templateUrl: 'footer.component.html'
})

export class FooterComponent implements OnInit {
    version: string = 'n/a'
    ngOnInit() {
        this.version = environment.version
    }
}
