import { Component, OnInit } from '@angular/core';

import { UserService } from '../biz/user.service';
import { User } from "../biz/_model/user";
import { Perfil } from "../biz/_model/perfil";
import { faMapSigns } from '@fortawesome/free-solid-svg-icons';

export class Pemissions {
    all: boolean = true;    
    consumidor: boolean = false;
    pesquisador: boolean = false;
    admin: boolean = false;

}
export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    roles: Pemissions;
    help?: string;
}

const defaultPemissions: Pemissions = {
    all: true,    
    consumidor: false,
    pesquisador: false,
    admin: false
}
const adminOnlyPemissions: Pemissions = {
    all: false,    
    consumidor: false,
    pesquisador: false,
    admin: true
}
export const ROUTES: RouteInfo[] = [
    {
        path: '/home/base', title: 'Base', icon: 'fa-map', class: '', roles: {
            all: true,            
            consumidor: true,
            pesquisador: true,
            admin: true
        },
        help:'Apresenta de forma simples e direta (a) as redes de estações por Instituição inseridas no banco de dados e (b) as bases de dados gradeados gerados por sensoriamento remoto e modelos meteorológicos. Redes de Estações e Bases de Dados foram nomeadas conforme instituição de origem.'
    },
    {
        path: '/home/instituicao', title: 'Instituições', icon: 'fa-university', class: '', roles: {
            all: false,            
            consumidor: false,
            pesquisador: false,
            admin: true
        },
        help:'Lista das instituições cadastradas no sistema. Funções de atribuição de modelos e usuários por instituição.'
    },
    {
        path: '/home/instituicao-grupo', title: 'Grupos', icon: 'fa-caret-right', class: 'sub_menu', roles: {
            all: false,            
            consumidor: false,
            pesquisador: false,
            admin: true
        }
    },
    {
        path: '/home/estacao', title: 'Estações', icon: 'fa-map-signs', class: '', roles: {
            all: false,            
            consumidor: false,
            pesquisador: false,
            admin: true
        },
        help:'Lista das Estações Cadastradas no Sistema. Funções de download e upload de dados.'
    },
    {
        path: '/home/usuario', title: 'Usuários', icon: 'fa-users', class: '', roles: {
            all: false,            
            consumidor: false,
            pesquisador: false,
            admin: true
        },
        help: 'Lista dos usuários cadastrados para acesso ao sistema.'
    },
    {
        path: '/home/consistencia', title: 'Indicadores de consistência', icon: 'fa-pie-chart', class: '', roles: {
            all: false,
            consumidor: false,
            pesquisador: true,
            admin: true
        },
        help:'Exibe os resultados do controle de qualidade. Permite selecionar uma ou mais redes de estações, uma variável, período de interesse e a categoria de interesse (válido, inválido e duvidoso, ausente). Os resultados são o percentual de dados válidos, inválidos e duvidosos ou ausentes identificados ao longo do período selecionado, conforme escala de cores.'
    },
    {
        path: '/home/visualizador-dados', title: 'Visualizador de dados', icon: 'fa-area-chart', class: '', roles: {
            all: true,
            consumidor: true,
            pesquisador: true,
            admin: true
        },
        help:'Exibe os dados meteorológicos registrados no banco. Permite selecionar uma ou mais redes de estações, de modelos, de uma ou mais variáveis e o período de interesse para consulta. Os resultados disponíveis no banco são exibidos nos gráficos de série temporal, histograma e dispersão. Estes gráficos peritem uma visualização rápida dos dados disponíveis, comparação de modelos com dados observados em estações, identificação de discrepância na distribuição de frequência e a qualidade da regressão/dispersão entre bases ou entre pontos próximos.'
    },
    {
        path: '/home/desempenho-mapa', title: 'Indicadores de desempenho', icon: 'fa-line-chart', class: '', roles: {
            all: false,
            consumidor: false,
            pesquisador: true,
            admin: true
        },
        help:'Exibe alguns indicadores de desempenho (R2, coeficientes da regressão, Willmot, Índice de Eficiência do Modelo e Erro Absoluto Médio) de dados gerados por modelos em relação a dados observados em estações. Os resultados podem ser visualizados no mapa nos pontos de estação, conforme modelo, estações, variável e período selecionados, ou podem ser obtidos no formato de tabelas em “Relatório”.'
    },
    {
        path: '/home/desempenho-report', title: 'Relatório', icon: 'fa-caret-right', class: 'sub_menu', roles: {
            all: false,
            consumidor: false,
            pesquisador: true,
            admin: true
        }
    },
    {
        path: '/home/desempenho-config', title: 'Config', icon: 'fa-caret-right', class: 'sub_menu', roles: {
            all: false,
            consumidor: false,
            pesquisador: false,
            admin: true
        }
    },


    {
        path: '/home/exportar', title: 'Exportar dados', icon: 'fa-file-archive-o', class: '', roles: {
            all: false,
            consumidor: false,
            pesquisador: true,
            admin: true
        },
        help:'Permite realizar o download de séries de dados meteorológicos, conforme opções de seleção. O usuário deve selecionar a rede de estações ou modelos de interesse, o período, uma variável por vez e, por último, definir um polígono sobre o mapa incluindo as estações ou região do seu interesse. Caso a consulta inclua uma área muito grande ou muitos pontos, o processo pode se tornar muito lento e sujeito a erros.'
    },
    {
        path: '/home/exportar-sarra', title: 'Formato sarra',  icon: 'fa-caret-right', class: 'sub_menu', roles: {
            all: false,
            consumidor: false,
            pesquisador: true,
            admin: true
        },
        help:'Formato SARRA'
    },
    {
        path: '/home/exportar-dssat', title: 'Formato DSSAT',  icon: 'fa-caret-right', class: 'sub_menu', roles: {
            all: false,
            consumidor: false,
            pesquisador: true,
            admin: true
        },
        help:'Formato DSSAT'
    },
    {
        path: '/home/exportar-agscosmos', title: 'Formato AGS e Ecosmos',  icon: 'fa-caret-right', class: 'sub_menu', roles: {
            all: false,
            consumidor: false,
            pesquisador: true,
            admin: true
        },
        help:'Formato AGS e Ecosmos'
    },
    
    {
        path: '/home/status', title: 'Status dados', icon: 'fa-bar-chart', class: '', roles: {
            all: false,
            consumidor: false,
            pesquisador: false,
            admin: true
        }
    },
    {
        path: '/home/script', title: 'Scripts de CQ', icon: 'fa-file-code-o', class: '', roles: {
            all: false,
            consumidor: false,
            pesquisador: false,
            admin: true
        }
    },


];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    styleUrls: ['./sidebar.component.css'],
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    faMapSigns = faMapSigns;
    public menuItems: any[];
    role: string;
    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => {            
            return menuItem.roles.all
        });

        this.user.getMe().subscribe(data => {
            this.role = data.perfil.nome;
            this.menuItems = ROUTES.filter(menuItem => {                
                return menuItem.roles.all || menuItem.roles[this.role]
            });
        });
    }
    constructor(
        private user: UserService
    ) {
    }

}
