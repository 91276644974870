import { Component, OnInit, Renderer2, ViewChild, ElementRef, Input } from '@angular/core';
import { ROUTES } from '../../sidebar/sidebar.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from 'app/biz/user.service';
import { AuthServiceService } from 'app/biz/auth-service.service';
import { UserIdleService } from 'angular-user-idle';

@Component({
  moduleId: module.id,
  selector: 'navbar-cmp',
  templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
  private listTitles: any[];
    location: Location;
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;
  private user: any;
  @Input('authenticatedSection')  authenticatedSection: boolean = true;

  public isCollapsed = true;
  @ViewChild("navbar-cmp", { static: false }) button;

  constructor(
    location: Location,
    private renderer: Renderer2,
    private element: ElementRef,
    private router: Router,
    private userSvc: UserService,
    private authSvc: AuthServiceService,
    private userIdle: UserIdleService) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }
  logout() {
    this.authSvc.logout();
    this.router.navigate(["/"]);
  }
  isLoggedIn(): boolean {
    return this.authSvc.isLoggedIn();
  }
  showIdle: boolean = false;
  idleSeconds = 0;
  public hideIdleMessage() {    
    this.showIdle = false;    
  }
  public showIdleMessage(seconds: number) {
    
    this.showIdle = true;
    this.idleSeconds = seconds;
  }
  

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);    
    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
    });
    
    if (this.authenticatedSection == true) {
      this.userSvc.getMe().subscribe(u => this.user = u);
    }
  }
  _getRouteConfig(){
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {        
        return this.listTitles[item];
      }
    }
    return null;
  }
  getHelp(){
    let rc = this._getRouteConfig();
    if(rc){
      return rc.help;
    }
    return '';
  }

  getTitle() {
    let rc = this._getRouteConfig();
    if(rc){
      return rc.title;
    }    
    return 'CON.PRE.ES';
  }
  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('sidebar-mini');
    // const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    // setTimeout(function () {
    //   toggleButton.classList.add('toggled');
    // }, 500);

    // html.classList.add('nav-open');
    // if (window.innerWidth < 991) {
    //   mainPanel.style.position = 'fixed';
    // }
    this.sidebarVisible = true;
  };
  sidebarClose() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('sidebar-mini');
    this.sidebarVisible = false;
    // const html = document.getElementsByTagName('html')[0];
    // const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    // if (window.innerWidth < 991) {
    //   setTimeout(function () {
    //     mainPanel.style.position = '';
    //   }, 500);
    // }
    // this.toggleButton.classList.remove('toggled');
    // this.sidebarVisible = false;
    // html.classList.remove('nav-open');
  };
  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName('nav')[0];
    
    if (!this.isCollapsed) {
      navbar.classList.remove('navbar-transparent');
      navbar.classList.add('bg-white');
    } else {
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }

  }

}
