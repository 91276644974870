// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { Mes } from "../app/biz/_model/uimodel/mes";
import version from '../../package.json';

export const environment = {
  version: version.version,
  production: true,
  internalPage: 'home/base',
  apiUrl: '/conprees_server',
  geoserverUrl: '/geoserver/conprees/wms',
  fake_back: false,
  datatables_language: {
    "emptyTable": "Nenhum registro encontrado",
    "info": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
    "infoEmpty": "Mostrando 0 até 0 de 0 registros",
    "infoFiltered": "(Filtrados de _MAX_ registros)",
    "infoPostFix": "",
    "infoThousands": ".",
    "lengthMenu": "_MENU_ resultados por página",
    "loadingRecords": "Carregando...",
    "processing": "Processando...",
    "zeroRecords": "Nenhum registro encontrado",
    "search": "Pesquisar: ",
    "paginate": {
      "next": "Próximo",
      "previous": "Anterior",
      "first": "Primeiro",
      "last": "Último"
    },
    "aria": {
      "sortAscending": ": Ordenar colunas de forma ascendente",
      "sortDescending": ": Ordenar colunas de forma descendente"
    }
  }
};
