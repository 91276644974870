import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpClientModule,
  HttpResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";


export class TokenInfo {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
}

@Injectable()
export class AuthServiceService {
  private tokenInfo: TokenInfo = null;

  constructor(private http: HttpClient) {
    this.tokenInfo = JSON.parse(localStorage.getItem("token"));
  }
  
  public getToken() {
    return this.tokenInfo.access_token;
  }

  public defineNewPassword(email, token, new_password) {
    var httpOptions = {
      observe: "response" as const,
      responseType: "json" as const,
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*"
      })
    };

    return this.http
      .post(
        `${environment.apiUrl}/public/auth/nova_senha`,
        {
          email: email,
          tokenSenha: token,
          senha: new_password
        },
        httpOptions
      )
      .pipe(
        map(token => {

          return true;
        }),
        catchError(this.handleError)
      );
  }

  public checkTokenResetSenha(token, email) {
    var httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Method": "*",
        "Access-Control-Allow-Origin": "*"
      })
    };
    return this.http
      .get(
        `${environment.apiUrl}/public/auth/nova_senha?token=${token}`,
        httpOptions
      )
      .pipe(
        map(token => {
          return true;
        }),
        catchError(this.handleError)
      );
  }
  public generatePasswordToken(email) {
    var httpOptions = {
      observe: "response" as const,
      responseType: "json" as const,
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*"
      })
    };

    return this.http
      .post(
        `${environment.apiUrl}/public/auth/lembrar_senha?email=${email}`,
        null,
        httpOptions
      )
      .pipe(
        map(token => {

          return true;
        }),
        catchError(this.handleError)
      );
  }
  public refreshToken() {
    var httpOptions = {
      observe: "response" as const,
      responseType: "json" as const,
      headers: new HttpHeaders({
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Method": "*",
        "Access-Control-Allow-Origin": "*"
      })
    };
    return this.http
      .post(
        `${environment.apiUrl}/rest/auth/v1/refreshtoken`,
        null,
        httpOptions
      )
      .pipe(
        map(token => {
          this.processToken(token.body as TokenInfo);

          return true;
        })
      );
  }
  processToken(tokenInfo: TokenInfo) {
    this.tokenInfo = tokenInfo;
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem("token", JSON.stringify(this.tokenInfo));
    let expires_at = new Date().getTime() + (this.tokenInfo.expires_in);
    let dd = new Date();
    dd.setTime(expires_at);
    console.info("Validade do token é até ",dd);
    localStorage.setItem("validity", expires_at + "");
  }
  public isLoggedIn() {
    let expires_at: any = localStorage.getItem("validity");
    let dd = new Date();
    dd.setTime(expires_at);
    
    let logged= this.tokenInfo != null && new Date().getTime() < (expires_at as number);
    if(!logged){
      console.log("Ainda valido?  ",expires_at>new Date().getTime(), " - expira em ",dd,"  --- ",this.tokenInfo);
    }
    return logged;
  }
  public authenticate(email, password) {

    var authInfo = btoa(email + ":" + password);
    var httpOptions = {
      observe: "response" as const,
      responseType: "json" as const,
      headers: new HttpHeaders({
        Authorization: "Basic " + authInfo,
        "Access-Control-Allow-Origin": "*"
      })
    };

    return this.http
      .post(
        `${environment.apiUrl}/public/auth/v1/token`,
        null,
        httpOptions
      )
      .pipe(
        map(token => {
          this.processToken(token.body as TokenInfo);

          return true;
        })
      );
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem("token");
    localStorage.removeItem("validity");
    this.tokenInfo = null;    
    

  }
  private handleError(error: HttpErrorResponse) {

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      if (error.error) {
        return throwError(error.error);


      }
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
