import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";

import { AuthServiceService } from "../auth-service.service";
import { ToastrService } from "ngx-toastr";
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private accountService: AuthServiceService,
    public toastr: ToastrService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.accountService.logout();
          this.router.navigate(["/login"]);
          
        } else if (err.status === 403) {
          console.log("devido a acesso invalido")
          this.accountService.logout();
          this.router.navigate(["/login"]);
          
          // this.toastr.warning(
          //   '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">Seu perfil não está autorizado a acessar essa funcionalidade.</span>',
          //   "Erro de acesso",
          //   {
          //     timeOut: 4000,
          //     closeButton: false,
          //     enableHtml: true,
          //     toastClass: "alert alert-warn alert-with-icon",
          //     positionClass: "toast-top-center"
          //   }

          // );
          // this.router.navigate(["/home/base"]);
          
        }

        // const error = err.error.message || err.statusText;
        return throwError(err);
      })
    );
  }
}
