import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { AuthServiceService } from 'app/biz/auth-service.service';
import { NavbarComponent } from 'app/shared/navbar/navbar.component';

import { ToastrService } from 'ngx-toastr';
import { IdleUserService } from '../../biz/_services/user_idle_services';


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  @ViewChild(NavbarComponent) navbar: NavbarComponent;

  constructor(private toastr: ToastrService, private router: Router, private authService: AuthServiceService, private idleUserService: IdleUserService) {

  }
  isUserIdle: boolean = false;
  ngOnInit() {
    console.log("Iniciando user idle")

    this.idleUserService.userInactive.subscribe(isIdle => {
      clearTimeout(this.refreshTime);
      this.isUserIdle = isIdle;
      this.authService.logout();
      this.router.navigate(["/"]);


    });
    this.idleUserService.userAboutToInactive.subscribe(timer => {
      this.navbar.showIdleMessage(timer);
    });
    this.idleUserService.userReActived.subscribe(isActive => {
      this.isUserIdle = false;
      this.navbar.hideIdleMessage();
    });




    //se usuario ficar recarregando as paginas o token nao sera renovado. Entao renovamos a cada recarregada
    this.authService.refreshToken().subscribe(t => {
      console.log('refreshed token - carregado');
      this.refreshTokenExec();
    });
  }
  refreshTime: any;
  refreshTokenExec() {
    this.refreshTime = setTimeout(() => {
      this.authService.refreshToken().subscribe(t => {
        console.log('refreshed token - recarregado');
        this.refreshTokenExec();
      }
      );

    }, 170000);
  }
}
