import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { AuthServiceService } from "../biz/auth-service.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-define-password",
  templateUrl: "./define-password.component.html",
  styleUrls: ["./define-password.component.css"]
})
export class DefinePasswordComponent implements OnInit {
  test: Date = new Date();
  focus;
  focus1;
  recoverPwdForm;
  loading = false;
  submitted = false;
  returnUrl: string;
  token: string;
  email: string;
  errorMesssage: string = '';
  resp: any;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private service: AuthServiceService
  ) {
    this.recoverPwdForm = this.formBuilder.group({
      password: "",
      confirm_password: ""
    });
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/home";
    this.errorMesssage = "";
    this.route.queryParams.subscribe(params => {
      
      if (!params['token']) {
        console.log('no token found');
      }
      if (!params['email']) {
        console.log('no email found');
      }
      this.token = params['token'];
      this.email = params['email'];
      this.service.checkTokenResetSenha(this.token, this.email)
        .subscribe(data => {
        }, error => {

          console.log("nok");
          this.toastr.error(
            '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">Token de redefinição de senha está inválido. Solicite um novo token.</span>',
            "",
            {
              timeOut: 4000,
              closeButton: true,
              enableHtml: true,
              toastClass: "alert alert-error alert-with-icon",
              positionClass: "toast-top-center"
            }
          );
           this.router.navigate(["/lembrar_senha"]);

        });

    });
  }
  showError(err) {
    if (err == 'embrapa_user') {
      this.errorMesssage = "O e-mail fornecido é de um usuário que usa a autenticação corporativa da Embrapa. Por favor contacte o serviço de TI da Embrapa para recuperar sua senha."
    } else {
      this.errorMesssage = "Houve uma falha no processo de recuperação de senha.";

    }


  }

  onSubmit(loginData) {
    this.errorMesssage = "";
    this.submitted = true;
    

    // stop here if form is invalid
    if (this.recoverPwdForm.invalid) {
      return;
    }
    // Process checkout data here
    this.service
      .defineNewPassword(this.email, this.token,loginData.password)
      .subscribe(data => {
        this.toastr.info(
          '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">Senha alterada com sucesso.</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-info alert-with-icon",
            positionClass: "toast-top-center"
          }
        );
         this.router.navigate(["/login"]);
      },
        error => {
          this.submitted = false;
          console.log(error);
          this.showError(error);
        });

    this.recoverPwdForm.reset();
  }
}
