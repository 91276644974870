import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthServiceService } from "../biz/auth-service.service";
@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"]
})
export class ForgotPasswordComponent implements OnInit {
  test: Date = new Date();
  focus;
  focus1;
  recoverPwdForm;
  loading = false;
  submitted = false;
  tokenSent=false;
  returnUrl: string;
  errorMesssage:string = '';
  resp: any;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private service: AuthServiceService
  ) {
    this.recoverPwdForm = this.formBuilder.group({
      email: ["", Validators.required],
      password: ""
    });
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/home";
    this.errorMesssage="";
  }
  showError(err){
    if(err=='embrapa_user'){
      this.errorMesssage="O e-mail fornecido é de um usuário que usa a autenticação corporativa da Embrapa. Por favor contacte o serviço de TI da Embrapa para recuperar sua senha."      
     }else{ 
      this.errorMesssage="Houve uma falha no processo de recuperação de senha." ;     

     }

    
  }

  onSubmit(loginData) {
    this.errorMesssage="";
    this.submitted = true;
    this.tokenSent=false;
    // stop here if form is invalid
    if (this.recoverPwdForm.invalid) {
      return;
    }
    // Process checkout data here
    this.service
      .generatePasswordToken(loginData.email)
      .subscribe(data => {
        this.tokenSent=true;
        this.toastr.info(
          '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">Um e-mail com instruções como redefinir sua senha foi enviado.</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-info alert-with-icon",
            positionClass: "toast-top-center"
          }
        );
      },
        error => {
          this.submitted = false;
          console.log(error);
          this.showError(error); 
        });

    this.recoverPwdForm.reset();
  }
}
